import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {getEnvironmentVariable, getRandomItem} from "../../utils";
import MicrophoneStream from "microphone-stream";
import HeadsetOffIcon from '@mui/icons-material/HeadsetOff';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import process from "process";
import {
    LanguageCode,
    StartStreamTranscriptionCommand,
    TranscribeStreamingClient
} from "@aws-sdk/client-transcribe-streaming";
import {Buffer} from "buffer";
import Questionnaire from "./modules/Questionnaire";
import {useParams} from "react-router-dom";
import {getDecisionCriteria} from "../../api/decisions.api";
import axios from "axios";
import './virtual-agent.scss';
import './application.scss';
import 'animate.css';
import {AppCriteria, AppResultInfo} from "../../types/decisions";

window.Buffer = Buffer;
window.process = process;

const heygen_API = {
    apiKey: getEnvironmentVariable('REACT_APP_VIRTUAL_AGENT_KEY'),
    serverUrl: getEnvironmentVariable('REACT_APP_VIRTUAL_AGENT_SERVER_URL'),
};

// Replace these with your AWS credentials
const AWS_REGION = getEnvironmentVariable('REACT_APP_AWS_REGION');
const AWS_ACCESS_KEY_ID = getEnvironmentVariable('REACT_APP_AWS_ACCESS_KEY_ID');
const AWS_SECRET_ACCESS_KEY = getEnvironmentVariable('REACT_APP_AWS_SECRET_ACCESS_KEY');

const apiKey = heygen_API.apiKey;
const SERVER_URL = heygen_API.serverUrl;

let transcription = "";
let question = '';
const language = "en-US";
const SAMPLE_RATE = 44100;
let silenceTimeout: NodeJS.Timeout | null = null;
const SILENCE_TIMEOUT = 3000;
const welcomeTexts = [
    'Hi there! I\'m Maria, your virtual assistant. Ready to start the journey?',
    'Hey! Maria here, your personal virtual agent. Click the start button to begin our journey!',
    'Hello! I\'m Maria, your virtual helper. Ready to embark on our journey together!',
    'Hi! I\'m Maria, your go-to virtual assistant. Let\'s start this journey—just click the start button!',
    'Hey! I\'m Maria, your virtual agent. Ready to start the journey? Click the start button to begin!'
];

const VirtualAgent = () => {
    const {id} = useParams<{ id: string }>();
    const isAppDataLoading = useRef(false);
    const [isBackgroundRemoved, setIsBackgroundRemoved] = useState(true);
    const avatarRef = useRef<HTMLInputElement>(null);
    const voiceRef = useRef<HTMLInputElement>(null);
    const sessionInfoRef = useRef<any>();
    const peerConnectionRef = useRef<any>();
    const mediaElementRef = useRef<HTMLVideoElement>(null);
    const canvasElementRef = useRef<HTMLCanvasElement>(null);
    const bgCheckboxWrapRef = useRef<HTMLDivElement>(null);

    const microphoneStream = useRef();
    const transcribeClient = useRef();
    const [isMicrophoneAccessible, setIsMicrophoneAccessible] = useState(true);
    const isListeningInProgress = useRef(false);
    const [isAgentReady, setAgentIsReady] = useState(false);
    const [criteriaLoading, setCriteriaLoading] = useState<boolean>(true);
    const [appData, setAppData] = useState<AppCriteria | null>(null);
    const virtualAgentCreated = useRef(false);
    const isAppReady = !criteriaLoading && appData?.id;
    const [products, setProducts] = useState<AppResultInfo[]>([]);
    const [options, setOptions] = useState<string[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const candidateIceInProgressTasksCount = useRef(0);
    const [areCandidateIcesReady, setAreCandidateIcesReady] = useState(false);

    useLayoutEffect(() => {
        if (id && !isAppDataLoading.current) {
            isAppDataLoading.current = true;
            getDecisionCriteria(id)
                .then(response => {
                    setAppData(response);
                })
                .finally(() => setCriteriaLoading(false));
        }

    }, [id]);

    useEffect(() => {
        if (apiKey === 'YourApiKey' || SERVER_URL === '') {
            alert('Please enter your API key and server URL in the api.json file');
        }
    }, []);

    useEffect(() => {
        if (isAgentReady && isAppReady && areCandidateIcesReady) {
            setTimeout(() => {
                readText(
                    sessionInfoRef.current?.session_id,
                    getRandomItem(welcomeTexts) + ' I will help you to find the product from ' + (appData?.name || 'the app'),
                );
            }, 1000);
        }

    }, [isAgentReady, isAppReady, appData?.name, areCandidateIcesReady]);

    // Function to handle incoming messages
    function onMessage() {
        // const message = event.data;
        // console.log('Received message:', message);
    }

    function monitorSilence(stream: MediaStream) {
        // @ts-ignore
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();

        const microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);

        analyser.fftSize = 512;
        const dataArray = new Uint8Array(analyser.fftSize);

        function checkForSilence() {
            analyser.getByteTimeDomainData(dataArray);

            let silenceDetected = true;
            for (let i = 0; i < dataArray.length; i++) {
                if (Math.abs(dataArray[i] - 128) > 10) {  // If signal is strong enough
                    silenceDetected = false;
                    break;
                }
            }

            // console.log('Silence detector works!');

            if (silenceDetected) {
                if (!silenceTimeout) {
                    silenceTimeout = setTimeout(() => {
                        // @ts-ignore
                        if (microphoneStream.current?.recorder.context.state === 'running') {
                            // fire an event to stop
                            document.dispatchEvent(new CustomEvent('virtual-agent-stop-listening', {
                                detail: {
                                    shouldFireRecordedEvent: true
                                }
                            }));
                        }
                    }, SILENCE_TIMEOUT);
                }
            } else {
                silenceTimeout && clearTimeout(silenceTimeout);
                silenceTimeout = null;
            }

            requestAnimationFrame(checkForSilence);
        }

        checkForSilence();
    }

    // Create a new WebRTC session when the page loads
    async function createNewSession() {
        console.log('session is starting');
        // call the new interface to get the server's offer SDP and ICE server to create a new RTCPeerConnection
        sessionInfoRef.current = await newSession('low', avatarRef.current?.value || '', voiceRef.current?.value || '');
        const {sdp: serverSdp, ice_servers2: iceServers} = sessionInfoRef.current;

        // Create a new RTCPeerConnection
        peerConnectionRef.current = new RTCPeerConnection({iceServers: iceServers});

        // When audio and video streams are received, display them in the video element
        peerConnectionRef.current.ontrack = (event: any) => {
            // console.log('Received the track');
            if (event.track.kind === 'audio' || event.track.kind === 'video') {
                if (!mediaElementRef.current) {
                    return;
                }

                mediaElementRef.current.srcObject = event.streams[0];
            }
        };

        // When receiving a message, display it in the console
        peerConnectionRef.current.ondatachannel = (event: any) => {
            const dataChannel = event.channel;
            dataChannel.onmessage = onMessage;
        };

        // Set server's SDP as remote description
        await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(serverSdp));

        console.log('Session creation completed');
        console.log('Now you can click the start button to start the stream');
        const isSessionUp = await startAndDisplaySession();
        if (isSessionUp) {
            setAgentIsReady(true);
        }
    }

    const renderProducts = (products: AppResultInfo[]) => {
        return (
            <div className='application'>
                <div className='application-details' style={{justifyContent: 'center'}}>
                    <div className='app-results'>
                        <div className='results-skin4' style={{margin: '32px auto'}}>
                            {products.map((result) => (
                                <div className='app-results-item' key={result.id}>
                                    <div className="image-container"
                                         style={{backgroundImage: `url('${result.icon}')`}}/>
                                    <div className='app-criteria-item-details'>
                                        <div className='app-criteria-item-details-rank'>
                                            <div className='app-criteria-item-details-rank-bar'
                                                 style={{width: result.rank + '%'}}/>
                                            <div className='app-criteria-item-details-rank-rate'>
                                                {result.rank === '-' ? <>&nbsp;</> : result.rank + '%'}
                                            </div>
                                        </div>
                                        <div>
                                            <h3 className='product-custom-header'>
                                                {typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}
                                            </h3>
                                            <div className='app-criteria-item-details-specs'>
                                                {Object.keys(result.data).map((key) => {
                                                    if (!/^f\d+$/.test(key)) {
                                                        return null;
                                                    }
                                                    // @ts-ignore
                                                    const {name, value} = result.data[key];
                                                    return (
                                                        <div className='app-criteria-item-details-specs-spec'
                                                             key={result.id + key}>
                                                            <span>{name}</span>
                                                            <span>{value}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className='app-criteria-item-details-ctas'/>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const onVirtualAgentReadText = async (event: CustomEvent) => {
        const text = event.detail.text.trim();
        const shouldListen = event.detail?.shouldListen || false;
        const products = event.detail?.products || [];
        const options = event.detail?.options;
        if (!text) {
            return;
        }

        setOptions([]);

        if (Array.isArray(products)) {
            setProducts(products);
        }

        question = text;
        console.log('Reading the text', question);
        readText(sessionInfoRef.current?.session_id, text)
            .then(response => {
                // console.log('Received the session response:', response);
                if (response && response.duration_ms && shouldListen) {
                    setTimeout(startListening, response.duration_ms - 1000 || 5);
                    setTimeout(() => setOptions(options), 1000);
                }
            })
            .catch(error => {
                console.log('Received the session error:', error);
            })
    }

    const onVirtualAgentStartListening = async () => {
        await startListening();
    }

    const onVirtualAgentStopListening = async (event: CustomEvent) => {
        const shouldFireRecordedEvent = event.detail.shouldFireRecordedEvent || false;
        const transcribedText = stopListening();
        if (shouldFireRecordedEvent) {
            // fire event to tell the recording has stopped
            document.dispatchEvent(new CustomEvent('virtual-agent-recorded', {
                detail: {
                    question,
                    answer: transcribedText
                }
            }));
        }
        question = '';
        transcription = '';
    }

    useEffect(() => {
        // Automatically create a new session when the DOM is fully loaded
        if (!virtualAgentCreated.current) {
            virtualAgentCreated.current = true;
            createNewSession();
        }


        // Create new aws streaming session
        createMicrophoneStream();

        // subscribe to virtual agent talk event
        // @ts-ignore
        document.addEventListener('virtual-agent-read', onVirtualAgentReadText);

        // subscribe to aws listen event
        // @ts-ignore
        document.addEventListener('virtual-agent-start-listening', onVirtualAgentStartListening);

        // subscribe to aws stop listening event
        // @ts-ignore
        document.addEventListener('virtual-agent-stop-listening', onVirtualAgentStopListening);

        return () => {
            // @ts-ignore
            document.removeEventListener('virtual-agent-read', onVirtualAgentReadText);
            // @ts-ignore
            document.removeEventListener('virtual-agent-start-listening', onVirtualAgentStartListening);
            // @ts-ignore
            document.removeEventListener('virtual-agent-stop-listening', onVirtualAgentStopListening);
            closeConnectionHandler();
            // @ts-ignore
            // onVirtualAgentStopListening({ detail: {}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Start session and display audio and video when clicking the "Start" button
    async function startAndDisplaySession() {
        if (!sessionInfoRef.current) {
            console.log('Please create a connection first');
            return false;
        }

        console.log('Starting session... please wait');

        // Create and set local SDP description
        const localDescription = await peerConnectionRef.current.createAnswer();
        await peerConnectionRef.current.setLocalDescription(localDescription);

        // When ICE candidate is available, send to the server
        peerConnectionRef.current.onicecandidate = async ({candidate}: any) => {
            // console.log('Received ICE candidate:', candidate);
            if (candidate) {
                await handleICE(sessionInfoRef.current.session_id, candidate.toJSON());
            }
        };

        // When ICE connection state changes, display the new state
        peerConnectionRef.current.oniceconnectionstatechange = () => {
            // console.log(`ICE connection state changed to: ${peerConnectionRef.current.iceConnectionState}`);
        };

        // Start session
        await startSession(sessionInfoRef.current.session_id, localDescription);

        let receivers = peerConnectionRef.current.getReceivers();
        receivers.forEach((receiver: any) => {
            receiver.jitterBufferTarget = 500;
        });

        console.log('Session started successfully');
        return true;
    }

    // When clicking the "Close" button, close the connection
    async function closeConnectionHandler() {
        if (!sessionInfoRef.current) {
            console.log('Please create a connection first');
            return;
        }

        // canvasElementRef.current && hideElement(canvasElementRef.current);
        // bgCheckboxWrapRef.current && hideElement(bgCheckboxWrapRef.current);

        console.log('Closing connection... please wait');
        try {
            // Close local connection
            peerConnectionRef.current.close();
            // Call the close interface
            await stopSession(sessionInfoRef.current.session_id);

            // console.log(resp);
        } catch (err) {
            console.error('Failed to close the connection:', err);
        }
        console.log('Connection closed successfully');
    }

    // Function to create a new session
    async function newSession(quality: string, avatar_name: string, voice_id: string) {
        try {
            const response = await axios.post(`${SERVER_URL}/v1/streaming.new`, {
                quality,
                avatar_name,
                voice: {
                    voice_id,
                },
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Api-Key': apiKey,
                },
            });
            return response.data.data;
        } catch (error) {
            console.error('Server error');
            setErrorMessage('Sorry! It seems all agents are busy now. Please try again later.');
            // throw new Error('Server error');
        }
    }

    // Function to start the session
    async function startSession(session_id: string, sdp: any) {
        const response = await fetch(`${SERVER_URL}/v1/streaming.start`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apiKey,
            },
            body: JSON.stringify({session_id, sdp}),
        });
        if (response.status === 500) {
            console.error('Server error');
            throw new Error('Server error');
        } else {
            const data = await response.json();
            return data.data;
        }
    }

    // Function to handle ICE candidates
    async function handleICE(session_id: string, candidate: any) {
        candidateIceInProgressTasksCount.current++;
        const response = await fetch(`${SERVER_URL}/v1/streaming.ice`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apiKey,
            },
            body: JSON.stringify({session_id, candidate}),
        });
        if (response.status === 500) {
            console.error('Server error');
            throw new Error('Server error');
        } else {
            candidateIceInProgressTasksCount.current--;
            if (candidateIceInProgressTasksCount.current === 0) {
                setAreCandidateIcesReady(true);
            }
            return await response.json();
        }
    }

    // Function to repeat the text
    async function readText(session_id: string, text: string) {
        const response = await fetch(`${SERVER_URL}/v1/streaming.task`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apiKey,
            },
            body: JSON.stringify({session_id, text}),
        });
        if (response.status === 500) {
            console.error('Server error');
            throw new Error('Server error');
        } else {
            const data = await response.json();
            return data.data;
        }
    }

    // Function to stop the session
    async function stopSession(session_id: string) {
        const response = await fetch(`${SERVER_URL}/v1/streaming.stop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Api-Key': apiKey,
            },
            body: JSON.stringify({session_id}),
        });
        if (response.status === 500) {
            console.error('Server error');
            throw new Error('Server error');
        } else {
            const data = await response.json();
            return data.data;
        }
    }

    function renderCanvas() {
        if (!mediaElementRef.current || !canvasElementRef.current) {
            return;
        }

        hideElement(mediaElementRef.current);
        showElement(canvasElementRef.current);

        canvasElementRef.current?.classList.add('show');

        const ctx = canvasElementRef.current.getContext('2d', {willReadFrequently: true});


        if (canvasElementRef.current.parentElement) {
            canvasElementRef.current.parentElement.style.background = '#f5f5f5';
        }

        function processFrame() {
            if (!mediaElementRef.current || !canvasElementRef.current) {
                return;
            }
            canvasElementRef.current.width = mediaElementRef.current.videoWidth;
            canvasElementRef.current.height = mediaElementRef.current.videoHeight;
            if (!ctx) {
                return;
            }

            ctx.drawImage(mediaElementRef.current, 0, 0, canvasElementRef.current.width, canvasElementRef.current.height);
            ctx.getContextAttributes().willReadFrequently = true;
            const imageData = ctx.getImageData(0, 0, canvasElementRef.current.width, canvasElementRef.current.height);
            const data = imageData.data;

            for (let i = 0; i < data.length; i += 4) {
                const red = data[i];
                const green = data[i + 1];
                const blue = data[i + 2];

                // You can implement your own logic here
                if (isCloseToGreen([red, green, blue])) {
                    data[i + 3] = 0;
                }
            }

            ctx.putImageData(imageData, 0, 0);

            requestAnimationFrame(processFrame);
        }

        processFrame();
    }

    function isCloseToGreen(color: [number, number, number]) {
        const [red, green, blue] = color;
        return green > 90 && red < 90 && blue < 90;
    }

    function hideElement(element: HTMLElement | null) {
        if (!element) {
            return;
        }

        element.classList.add('hide');
        element.classList.remove('show');
    }

    function showElement(element: HTMLElement | null) {
        if (!element) {
            return;
        }

        element.classList.add('show');
        element.classList.remove('hide');
    }

    const onLoadedmetadataHandler = () => {
        mediaElementRef.current?.play();

        renderCanvas()
    };

    const createMicrophoneStream = async () => {
        // @ts-ignore
        microphoneStream.current = new MicrophoneStream();
        window.navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
        })
            .then(result => {
                // @ts-ignore
                microphoneStream.current?.setStream(result);
                setIsMicrophoneAccessible(true);
            })
            .catch(() => {
                setIsMicrophoneAccessible(false);
                console.log('Unable to listen to you, please check the microphone access.')
            })
    };

    const createTranscribeClient = () => {
        // @ts-ignore
        transcribeClient.current = new TranscribeStreamingClient({
            region: AWS_REGION,
            credentials: {
                accessKeyId: AWS_ACCESS_KEY_ID,
                secretAccessKey: AWS_SECRET_ACCESS_KEY,
            },
        });
    };

    const encodePCMChunk = (chunk: any) => {
        const input = MicrophoneStream.toRaw(chunk);
        let offset = 0;
        const buffer = new ArrayBuffer(input.length * 2);
        const view = new DataView(buffer);
        for (let i = 0; i < input.length; i++, offset += 2) {
            let s = Math.max(-1, Math.min(1, input[i]));
            view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
        }
        return Buffer.from(buffer);
    };

    const getAudioStream = async function* () {
        // @ts-ignore
        for await (const chunk of microphoneStream.current) {
            if (chunk.length <= SAMPLE_RATE) {
                yield {
                    AudioEvent: {
                        AudioChunk: encodePCMChunk(chunk),
                    },
                };
            }
        }
    };

    const startStreaming = async (language: LanguageCode, callback: (text: string) => void) => {
        const command = new StartStreamTranscriptionCommand({
            LanguageCode: language,
            MediaEncoding: "pcm",
            MediaSampleRateHertz: SAMPLE_RATE,
            AudioStream: getAudioStream(),
        });

        // @ts-ignore
        const data = await transcribeClient.current.send(command);
        // @ts-ignore
        const stream: MediaStream = microphoneStream.current?.stream;
        monitorSilence(stream);
        for await (const event of data.TranscriptResultStream) {
            const results = event.TranscriptEvent.Transcript.Results;
            if (results.length && !results[0]?.IsPartial) {
                const newTranscript = results[0].Alternatives[0].Transcript;
                callback(newTranscript + " ");
            }
        }
    };

    const startRecording = async (callback: (text: string) => void) => {
        if (!AWS_REGION || !AWS_ACCESS_KEY_ID || !AWS_SECRET_ACCESS_KEY) {
            alert("Set AWS env variables first.");
            return false;
        }

        if (microphoneStream.current || transcribeClient.current) {
            stopRecording();
        }

        createTranscribeClient();
        await createMicrophoneStream();
        await startStreaming(language, callback);
    };

    const stopRecording = function () {
        if (microphoneStream.current) {
            // @ts-ignore
            microphoneStream.current?.stop();
            // @ts-ignore
            microphoneStream.current?.destroy();
            microphoneStream.current = undefined;
        }
    };

    const startListening = async () => {
        if (!isMicrophoneAccessible) {
            return;
        }

        isListeningInProgress.current = true;
        console.log('Listening');
        await startRecording((text) => {
            transcription += text;
            console.log(transcription);
        });
    };

    const stopListening = () => {
        if (!isMicrophoneAccessible) {
            return;
        }
        isListeningInProgress.current = false;

        stopRecording();
        return transcription;
    };

    const shouldRenderOptions = options.length > 3;

    return (
        <div className='virtual-agent'>
            <div className="main">
                <h1>&nbsp;</h1>
                {errorMessage ?
                    <b className='error-message'>{errorMessage}</b>
                    : (<>
                            {isAppReady ? (
                                <h2>{appData?.name}</h2>
                            ) : (
                                <div>Loading the application for you...</div>
                            )}
                        </>
                    )
                }
                <div style={{display: isAppReady ? 'block' : 'none'}}>
                    <div className="actionRowsWrap">
                        <div className="actionRow">
                            <input id="avatarID" type="text" ref={avatarRef}/>
                            <input id="voiceID" type="text" ref={voiceRef}/>
                        </div>
                        <div className="actionRow">
                            {!isMicrophoneAccessible && (
                                <>
                                    <HeadsetOffIcon color='error'/>
                                    <div className='error-message'>
                                        We are unable to access your microphone. Please check the microphone
                                        permissions.
                                    </div>
                                </>
                            )}
                            {isListeningInProgress.current && (
                                <>
                                    <HeadsetMicIcon/>
                                    <div>I'm listening to you</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={`videoSectionWrap ${shouldRenderOptions ? 'contains-options' : ''}`}>
                        <div className="videoWrap">
                            <video
                                id="mediaElement"
                                className="videoEle show"
                                autoPlay
                                ref={mediaElementRef}
                                onLoadedMetadata={onLoadedmetadataHandler}
                            ></video>
                            <canvas id="canvasElement" className="videoEle hide" ref={canvasElementRef}></canvas>
                        </div>

                        <ul className={`question-options ${!shouldRenderOptions ? 'hide' : ''}  `}>
                            {shouldRenderOptions ? options.map((item) => <li key={item}>{item}</li>) : null}
                        </ul>

                        <div className="actionRow switchRow hide" id="bgCheckboxWrap" ref={bgCheckboxWrapRef}>
                            <div className="switchWrap" style={{display: 'none'}}>
                                <span>Remove background</span>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        id="removeBGCheckbox"
                                        onChange={(e) => setIsBackgroundRemoved(e.target.checked)}
                                        checked={isBackgroundRemoved}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    {!errorMessage && <>
                        {isAgentReady && id ? <Questionnaire appId={+id}/> : (<div>Loading.</div>)}
                    </>}
                    {products ?
                        <div className='animate__tada'>
                            {renderProducts(products)}
                        </div>
                        : null
                    }
                </div>
            </div>
        </div>
    );
};

export default VirtualAgent;