import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

const Logout = () => {
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.removeItem('AUTH_TOKEN');
        localStorage.removeItem('USERNAME');
        // todo implement logout in platform
        navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            Logging out...
        </div>
    );
};

export default Logout;