import {Swiper, SwiperSlide, SwiperProps} from 'swiper/react';
import {Decision} from "../../types/decisions";
import ProductCart from "../productCart/productCart";
import {Navigation} from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.scss';
import {useRef} from "react";


const BaseSwiper = ({items, onReachEnd}: { onReachEnd: () => void, items: Decision[] }) => {
    let swiperRef = useRef<HTMLDivElement>();
    const settings: SwiperProps = {
        className: 'swiper-container',
        spaceBetween: 20,
        slidesPerGroup: 3,
        slidesPerView: 4,
        navigation: true,
        modules: [Navigation],
        onReachEnd: onReachEnd,
        breakpoints: {
            1: {
                slidesPerView: 1,
                slidesPerGroup: 1,
            },
            640: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
            1440: {
                slidesPerView: 3,
            },
            1600: {
                slidesPerView: 4,
            },
        },
    };
    const onPrevSlide = () => {
        const nextSlideElement = swiperRef.current?.querySelector('.swiper-button-prev');
        // @ts-ignore
        nextSlideElement?.click();
    };
    const onNextSlide = () => {
        const nextSlideElement = swiperRef.current?.querySelector('.swiper-button-next');
        // @ts-ignore
        nextSlideElement?.click();
    };

    return (
        <div className='swiper-main-container'>
            <Swiper {...settings} ref={swiperRef}>
                {items.map((item, index) => (
                    <SwiperSlide key={item.id}>
                        <ProductCart product={item}/>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className="swiper-button-prev custom-navigation-button custom-navigation-button-prev" onClick={onPrevSlide}/>
            <div className="swiper-button-next custom-navigation-button custom-navigation-button-next" onClick={onNextSlide}/>
        </div>
    );
};

export default BaseSwiper;