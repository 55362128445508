import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {useMediaQuery} from "react-responsive";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{py: 3}}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs({tabNames, tabs}: {tabNames: string[], tabs: React.ReactNode[]}) {
    const [value, setValue] = React.useState(0);
    const isMobile = useMediaQuery({
        query: '(max-width: 768px)'
    });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabWidth = isMobile ? `calc(100% / ${tabNames.length})` : 'inherit';

    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
                    {tabNames.map((tab: string) => (
                        <Tab key={tab} label={tab} {...a11yProps(0)} style={{ width: tabWidth }} />
                    ))}
                </Tabs>
            </Box>
            {tabs.map((tab, index) => (
                <CustomTabPanel value={value} index={index} key={index}>
                    {tab}
                </CustomTabPanel>
            ))}
        </Box>
    );
}
