import React, {useLayoutEffect, useRef, useState} from 'react';
import {getTermsOfUse} from "../../api/utils.api";
import './TermsOfUse.scss'

const TermsOfUse = () => {
    const isLoadingRef = useRef(false);
    const [isLoading, setLoading] = useState(false);
    const [pageInfo, setPageInfo] = useState({title: '', description: '', content: 'Terms of use.'});

    useLayoutEffect(() => {
        if (!isLoadingRef.current) {
            setLoading(true);
            isLoadingRef.current = true;
            getTermsOfUse()
                .then(response => {
                    setPageInfo(response);
                })
                .catch((e) => {
                    console.error(e);
                    setPageInfo({title: '', description: '', content: 'Failed to load the terms of use.'})
                })
                .finally(() => {
                    setLoading(false);
                    isLoadingRef.current = false;
                });
        }
    }, []);

    return (
        <div className='terms-of-use'>
            {isLoading ? (
                <>Loading...</>
            ) : (
                <div
                    dangerouslySetInnerHTML={{__html: pageInfo.content}}
                />
            )}
        </div>
    );
};

export default TermsOfUse;