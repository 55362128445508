import React, {useState, useRef, useCallback, CSSProperties, useEffect} from 'react';
import {GoogleMap, LoadScript, Marker, StandaloneSearchBox} from '@react-google-maps/api';
import {toast} from "react-toastify";

const containerStyle = {
    width: '100%',
    height: '400px',
};

const defaultCoords = {
    lat: -3.745,
    lng: -38.523
};

const POINTS_MAX_COUNT = 6;
const DEFAULT_ZOOM = 15;

const inputStyle: CSSProperties = {
    boxSizing: `border-box`,
    border: `1px solid transparent`,
    width: `240px`,
    height: `32px`,
    padding: `0 12px`,
    borderRadius: `3px`,
    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
    fontSize: `14px`,
    outline: `none`,
    textOverflow: `ellipses`,
    position: 'absolute',
    top: '10px',
    right: '10px',
};

interface MapProps {
    onPointSelect: (items: { lat: number, lng: number }[]) => void
}

const Map = ({ onPointSelect }: MapProps) => {
    const [markers, setMarkers] = useState<{ lat: number, lng: number }[]>([]);
    const [center, setCenter] = useState(defaultCoords);
    const mapRef = useRef<google.maps.Map | null>(null);
    const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

    useEffect(() => {
        onPointSelect(markers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markers]);

    const onLoad = useCallback((map: google.maps.Map) => {
        mapRef.current = map;
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
    }, [center]);

    const fitBounds = useCallback((markers: { lat: number, lng: number }[]) => {
        if (mapRef.current && markers.length > 1) {
            const bounds = new google.maps.LatLngBounds();
            markers.forEach((marker) => bounds.extend(new google.maps.LatLng(marker.lat, marker.lng)));
            mapRef.current.fitBounds(bounds);
        }
    }, []);

    const onMapClick = useCallback((event: google.maps.MapMouseEvent) => {
        if (event.latLng) {
            if (markers.length >= POINTS_MAX_COUNT) {
                return toast.warning(`Max count of points (${POINTS_MAX_COUNT}) has reached`);
            }

            const newMarker = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            setMarkers((current) => [...current, newMarker]);
            fitBounds([...markers, newMarker]);
        }
    }, [fitBounds, markers]);

    const requestCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    setCenter({lat, lng});
                },
                function (error) {
                    console.error("Error Code = " + error.code + " - " + error.message);
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
        }
    };

    useEffect(() => {
        requestCurrentLocation();
    }, []);

    useEffect(() => {
        const currentZoom = mapRef.current?.getZoom();
        if (!currentZoom || currentZoom > DEFAULT_ZOOM) {
            mapRef.current?.setZoom(DEFAULT_ZOOM);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef.current]);

    const onSearchLoad = (ref: google.maps.places.SearchBox) => {
        searchBoxRef.current = ref;
    };

    const onPlacesChanged = () => {
        const places = searchBoxRef.current?.getPlaces();
        if (places && places.length > 0) {
            const place = places[0];
            const location = place.geometry?.location;
            if (location) {
                setCenter({
                    lat: location.lat(),
                    lng: location.lng(),
                });
            }
        }
    };

    const removeMarker = (marker: { lat: number, lng: number }) => {
        setMarkers(markers =>
            markers.filter(({lat, lng}) =>
                marker.lat !== lat && marker.lng !== lng
            )
        );
    };

    return (
        <LoadScript
            googleMapsApiKey='AIzaSyBYACuAZLAad-uadZ8NViL8tQCiiy-JeQM'
            libraries={['places']}
        >
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                onLoad={onLoad}
                onClick={onMapClick}
            >
                <StandaloneSearchBox onPlacesChanged={onPlacesChanged} onLoad={onSearchLoad}>
                    <input
                        type='text'
                        placeholder='Search...'
                        style={inputStyle}
                    />
                </StandaloneSearchBox>
                {markers.map((marker, index) => (
                    <Marker key={index} position={marker} onClick={() => removeMarker(marker)}/>
                ))}
            </GoogleMap>
        </LoadScript>
    );
};

export default Map;
