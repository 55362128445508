import axios from "axios";
import {getEnvironmentVariable} from "../utils";

interface PageDTO {
    id: number,
    title: string,
    icon: string,
    position: number,
    route: string,
    tag: string | null,
    type: string,
    content: string,
    metha: string | null,
    viewport: string | null,
    author: string | null,
    keywords: string | null,
    description: string,
    enabled: number
}

const platformUrl = getEnvironmentVariable('REACT_APP_PLATFORM_URL');

export const getTermsOfUse = async () => {
    const response = await axios.post<PageDTO>(`${platformUrl}/pages/detail?route=termsofuse`);
    const {title, description, content} = response.data;
    return {
        title,
        description,
        content
    };
}