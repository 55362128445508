import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from "./components/header";
import Footer from "./components/footer";
import Application from "./components/application";
import Applications from "./components/applications";
import TermsOfUse from "./pages/terms-of-use/TermsOfUse";
import VirtualAgent from "./components/VirtualAgent";
import Logout from "./pages/logout/Logout";

import './app.scss';

function App() {
    return (
        <div className='app'>
            <Router>
                <Header/>
                <Routes>
                    <Route path="/" element={<Applications/>}/>
                    <Route path="/apps/:id" element={<Application/>}/>
                    <Route path="/terms-of-use" element={<TermsOfUse />}/>
                    <Route path="/virtual-agent/:id" element={<VirtualAgent />}/>
                    <Route path="/logout" element={<Logout />}/>
                    <Route path="*" element={<h2 style={{ padding: '0 16px'}}>Coming soon...</h2>}/>
                </Routes>
                <Footer/>
            </Router>
        </div>
    );
}

export default App;


// TODO
// 1. add eslint
// 2. prettier
// 3. setup pre-commit hooks
// 4. add editorConfig
