/* eslint-disable */
import React, {useEffect, useLayoutEffect, useRef} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {rankApps, RankFilters} from "../../../api/decisions.api";
import {getRandomItem} from "../../../utils";
import {Button} from "@mui/material";

// const APP_ID = 352;
const API_TOKEN = '636901c7877c4a686e2d0f1249f5a009c700f6f2';
const API_URL = 'https://module3.dev.sparkdit.com';

const positiveReactions = [
    'Okay!',
    'I see!',
    'Very well!',
    'Noted!',
    '',
];

const nonPositiveReactions = [
    ''
];

const loadingQuestionsReactions = [
    'Okay! Let me think about it!',
    'Interesting! I like the way you think!',
    'Ha ha ha! Is it really what you care about? Joking! Give me a second...',
    'Perfect! I will ask you couple of more questions.'
];

const transformCriteria = (criteria: RankFilters['criteria']): RankFilters['criteria'] => {
    // @ts-ignore
    return criteria.map(item => ({
        ...item,
        // @ts-ignore
        value: item.value === '' ? '-' : item.value
    }));
};

const Questionnaire = ({appId}: { appId: number }) => {
    const navigate = useNavigate();
    const currentQuestion = useRef();
    const currentQuestionIndex = useRef(0);
    const questions = useRef([]);
    const isRetry = useRef(false);
    const isFirstQuestion = useRef(true);
    const answeredText = useRef('');
    const scoreCriteriaRef = useRef(null);
    const rankRef = useRef<{} | null>(null);
    const initialRankRef = useRef<{} | null>(null);
    const isRankLoading = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const onGetQuestionAnswer = async (event: CustomEvent) => {
        const {answer} = event.detail;

        answeredText.current = answer;
        handleQuestionResponse();
    };

    // this function is used to get the 'rank' and save it to a constant RankData,
    // which will be updated as we go over the questions and then use it as input to get the results calling Sparkdit apis
    const getRank = async () => {
        try {
            const response = await axios.get(`${API_URL}/rank`, {
                params: {
                    app_id: appId,
                    api_token: API_TOKEN
                }
            });

            return response.data;
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    const fetchRank = async () => {
        isRankLoading.current = true;
        const response = await getRank();
        if (response) {
            initialRankRef.current = response;
            rankRef.current = response;
        }
    };

    useLayoutEffect(() => {
        if (!isRankLoading.current) {
            fetchRank();
        }
    }, []);

    const navigateToAppPage = () => {
        // fire an event to virtual agent to tell she didn't get the response
        document.dispatchEvent(new CustomEvent('virtual-agent-read', {
            detail: {
                text: 'Sorry! It seems I\'m not able to understand your response. I will redirect you to the application page! See you next time!',
            }
        }));

        timeoutRef.current = setTimeout(() => {
            navigate(`/apps/${appId}`)
        }, 10000)
    }

    const agentSpeak = (text: string) => {
        document.dispatchEvent(new CustomEvent('virtual-agent-read', {
            detail: {
                text,
            }
        }));
    }

    const askCurrentQuestion = () => {
        // @ts-ignore
        let questionToAsk = currentQuestion.current.question1;
        if (isRetry.current) {
            // @ts-ignore
            questionToAsk = currentQuestion.current.question2
        }
        const reaction = isFirstQuestion.current || currentQuestionIndex.current === 1 ? ''
            : getRandomItem(isRetry.current ? nonPositiveReactions : positiveReactions);
        document.dispatchEvent(new CustomEvent('virtual-agent-read', {
            detail: {
                text: reaction + ' ' + questionToAsk,
                shouldListen: true,
                products: [],
                // @ts-ignore
                options: currentQuestion.current?.options || [],
            }
        }));
    };

    const renderTheResult = async () => {
        // @ts-ignore
        const {results} = await rankApps({id: appId, criteria: transformCriteria(rankRef.current.criteria)}, 5, 1);
        const resultsToShow = results.slice(0, 3);

        const names = resultsToShow.map((item: any) => item.data.name.value);
        const lastName = names.pop();
        const namesText = names.join(', ');

        document.dispatchEvent(new CustomEvent('virtual-agent-read', {
            detail: {
                text: `Okay! Here we go! These are the top ${resultsToShow.length} products based on your preferences: ${namesText} and ${lastName}`,
                products: resultsToShow
            }
        }));
    }

    const handleQuestionResponse = async () => {
        let isAnswerClear = await checkTheAnswer();

        if (isAnswerClear) {
            if (isFirstQuestion.current) {
                agentSpeak(getRandomItem(loadingQuestionsReactions));
                // @ts-ignore
                const mainQuestionsAreLoaded = await loadMainQuestions(currentQuestion.current.options);
                if (mainQuestionsAreLoaded) {
                    isAnswerClear = await updateTradeoffScore();
                }
            } else {
                isAnswerClear = await updateTradeoffScoreForCurrentCriteria();
            }
        }

        if (!isAnswerClear) {
            if (isRetry.current) {
                // asked 2 times again, but still didn't get the response
                return navigateToAppPage();
            }
            isRetry.current = true;
            answeredText.current = '';
            return askCurrentQuestion();
        }


        if (currentQuestionIndex.current < questions.current.length) {
            resetCurrentQuestionState();
            currentQuestion.current = questions.current[currentQuestionIndex.current++];
            askCurrentQuestion();
            return;
        }

        return renderTheResult();
    };

    useEffect(() => {
        // @ts-ignore
        document.addEventListener('virtual-agent-recorded', onGetQuestionAnswer);

        return () => {
            // @ts-ignore
            document.removeEventListener('virtual-agent-recorded', onGetQuestionAnswer);
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
    }, []);

    const fetchFirstQuestion = async () => {
        try {
            const response = await axios.get(`${API_URL}/starting-questions`, {
                params: {
                    app_id: appId,
                    api_token: API_TOKEN
                }
            });
            // @ts-ignore
            currentQuestion.current = {
                question1: response.data[0][1],
                question2: response.data[0][2],
                options: response.data[1],
                type: 'select',
            };
        } catch (error) {
            console.error('Error fetching the first question:', error);
        }
    };

    const checkResponse = async (savedResponse: string, question: string, listCriteria = []) => {
        if (savedResponse.trim() === '') return;

        try {
            const response = await axios.get(`${API_URL}/process-intent`, {
                params: {
                    user_input: savedResponse,
                    question,
                    list_criteria: (listCriteria || []).join(', '),
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error checking the response:', error);
        }
    };

    const checkNumberResponse = async (savedResponse: string, question: string) => {
        if (savedResponse.trim() === '') return false;

        try {
            const response = await axios.get(`${API_URL}/process-intent-number`, {
                params: {
                    user_input: savedResponse,
                    question,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error checking the response:', error);
            return false;
        }
    };

    const checkTextResponse = async (savedResponse: string, question: string) => {
        if (savedResponse.trim() === '') return false;

        try {
            const response = await axios.get(`${API_URL}/process-intent-text`, {
                params: {
                    user_input: savedResponse,
                    question,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error checking the response:', error);
            return false;
        }
    };

    const checkGeolocResponse = async (savedResponse: string, question: string) => {
        if (savedResponse.trim() === '') return false;

        try {
            const response = await axios.get(`${API_URL}/process-intent-geoloc`, {
                params: {
                    user_input: savedResponse,
                    question,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error checking the response:', error);
            return false;
        }
    };

    const checkDateResponse = async (savedResponse: string, question: string) => {
        if (savedResponse.trim() === '') return false;

        try {
            const response = await axios.get(`${API_URL}/process-intent-date`, {
                params: {
                    user_input: savedResponse,
                    question,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error checking the response:', error);
            return false;
        }
    };

    const checkZipResponse = async (savedResponse: string, question: string) => {
        if (savedResponse.trim() === '') return false;

        try {
            const response = await axios.get(`${API_URL}/process-intent-zipcode`, {
                params: {
                    user_input: savedResponse,
                    question,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error checking the response:', error);
            return false;
        }
    };

    const checkTheAnswer = async () => {
        // @ts-ignore
        const checkFunction = chooseTheAPIToIntent(currentQuestion.current.type);

        if (answeredText.current) {
            return checkFunction(
                answeredText.current,
                // @ts-ignore
                currentQuestion.current.question1,
                // @ts-ignore
                currentQuestion.current.options ? currentQuestion.current.options : [],
            );
        }

        return false;
    }

    const resetCurrentQuestionState = () => {
        currentQuestion.current = undefined;
        isRetry.current = false;
    };

    const updateTradeoffScoreNumber = async (criteria: string, text: string, rank: any) => {
        try {
            const res = await axios.post(`${API_URL}/update-number-rank`, {
                text,
                criteria,
                rank,
            });
            if (res.data) {
                rankRef.current = res.data;
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating tradeoff score:', error);
            // @ts-ignore
            if (error.response) {
                // @ts-ignore
                console.error('Server response:', error.response.data);
            }
            return false;
        }
    };

    const updateTradeoffScoreText = async (criteria: string, text: string, rank: any) => {
        try {
            const res = await axios.post(`${API_URL}/update-text-rank`, {
                text,
                criteria,
                rank
            });
            if (res.data) {
                rankRef.current = res.data;
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating tradeoff score:', error);
            // @ts-ignore
            if (error.response) {
                // @ts-ignore
                console.error('Server response:', error.response.data);
            }
            return false;
        }
    };

    const updateTradeoffScoreZipcode = async (criteria: string, text: string, rank: any) => {
        try {
            const res = await axios.post(`${API_URL}/update-zipcode-rank`, {
                text,
                criteria,
                rank,
            });
            if (res.data) {
                rankRef.current = res.data;
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating tradeoff score:', error);
            // @ts-ignore
            if (error.response) {
                // @ts-ignore
                console.error('Server response:', error.response.data);
            }
            return false;
        }
    };

    const updateTradeoffScoreDate = async (criteria: string, text: string, rank: any) => {
        try {
            const res = await axios.post(`${API_URL}/update-date-rank`, {
                text,
                criteria,
                rank
            });
            if (res.data) {
                rankRef.current = res.data;
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating tradeoff score:', error);
            // @ts-ignore
            if (error.response) {
                // @ts-ignore
                console.error('Server response:', error.response.data);
            }
            return false;
        }
    };

    const updateTradeoffEnumOrdBool = async (criteria: string, text: string, rank: any, list_input = []) => {
        try {
            const res = await axios.post(`${API_URL}/update-enum-ord-rank`, {
                text,
                criteria,
                rank,
                list_input,
            });
            if (res.data) {
                rankRef.current = res.data;
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating tradeoff score:', error);
            // @ts-ignore
            if (error.response) {
                // @ts-ignore
                console.error('Server response:', error.response.data);
            }
            return false;
        }
    };

    const updateTradeoffScoreForCurrentCriteria = async () => {
        // @ts-ignore
        const updateFunction = chooseTheAPIToUpdateTradeoffs(currentQuestion.current.type);

        if (answeredText.current) {
            return updateFunction(
                // @ts-ignore
                currentQuestion.current.name,
                answeredText.current,
                rankRef.current,
                // @ts-ignore
                currentQuestion.current.options || []
            );
        }

        return false;
    }

    const updateTradeoffScore = async () => {
        if (!scoreCriteriaRef.current) {
            return false;
        }

        try {
            const res = await axios.post(`${API_URL}/update-tradeoff-score`, {
                scores: scoreCriteriaRef.current,
                rank: rankRef.current
            });
            if (res.data) {
                rankRef.current = res.data;
                return true;
            }
            return false;
        } catch (error) {
            console.error('Error updating tradeoff score:', error);
            // @ts-ignore
            if (error.response) {
                // @ts-ignore
                console.error('Server response:', error.response.data);
            }
            return false;
        }
    };

    const startTheSession = () => {
        fetchFirstQuestion().then(() => {
            askCurrentQuestion();
        });
    };

    useEffect(() => {
        if (scoreCriteriaRef.current) {
            updateTradeoffScore();
        }
    }, [scoreCriteriaRef.current]);

    const loadMainQuestions = async (options = []) => {
        try {
            const res = await axios.post(`${API_URL}/generate-field-questions`, {
                text: answeredText.current,
                list_input: options,
                app_id: appId,
                api_token: API_TOKEN
            });

            questions.current = res.data.questions[0];
            scoreCriteriaRef.current = res.data.questions[1];
            isFirstQuestion.current = false;
            return true;
        } catch (error) {
            console.error('Error updating importance:', error);
            return false;
        }
    };

    const chooseTheAPIToIntent = (type: string) => {
        switch (type) {
            case 'number':
                return checkNumberResponse;

            case 'select':
            case 'multiselect':
            case 'bool':
                return checkResponse;

            case 'text':
                return checkTextResponse

            case 'geoloc':
                return checkGeolocResponse

            case 'date':
                return checkDateResponse

            case 'zipcode':
                return checkZipResponse

            default:
                return () => console.log('No Intent function found for type "' + type + '".');
        }
    };

    const chooseTheAPIToUpdateTradeoffs = (type: string) => {
        switch (type) {
            case 'number':
                return updateTradeoffScoreNumber;

            case 'select':
            case 'multiselect':
            case 'bool':
                return updateTradeoffEnumOrdBool;

            case 'text':
                return updateTradeoffScoreText;

            case 'date':
                return updateTradeoffScoreDate;

            case 'zipcode':
                return updateTradeoffScoreZipcode;

            default:
                return () => {
                    console.log('No update tradeoff score function found for type "' + type + '".');
                    return false;
                }
        }
    }

    const restartSession = () => {
        currentQuestionIndex.current = 0;
        currentQuestion.current = undefined;
        questions.current = [];
        isRetry.current = false;
        isFirstQuestion.current = true;
        if (initialRankRef.current) {
            rankRef.current = {
                ...initialRankRef.current
            };
        }
        startTheSession();
        // window.location.reload();

    }

    const isSessionStarted = currentQuestion.current !== undefined;

    return (
        <>
            {isSessionStarted ? (
                <Button
                    onClick={restartSession}
                    type='submit'
                    variant='contained'
                >
                    Restart the session
                </Button>
            ): (
                <Button
                    onClick={startTheSession}
                    type='submit'
                    variant='contained'
                >
                    Start the session
                </Button>
            )}
        </>
    );
};

export default Questionnaire;
