import axiosInstance from './base.api';
import {Decision, DecisionsResponse} from "../types/decisions";
import {getEnvironmentVariable} from "../utils";

interface PaginationInfo {
    currentPage: number;
    pageSize: number;
}

// "f1": {
//             "operator": "OR",
//             "left": {
//                 "rule": "=",
//                 "value": "59.99"
//             },
//             "right": {
//                 "operator": "AND",
//                 "left": {
//                     "rule": ">",
//                     "value": "200"
//                 },
//                 "right": {
//                     "rule": "<",
//                     "value": "370"
//                 }
//             }
//         },

export interface CriteriaConstraintField {
    rule: string;
    value: string;
}

export interface CriteriaConstraintItem {
    operator: 'and' | 'or' | null;
    left: CriteriaConstraintField;
    right?: CriteriaConstraintField | CriteriaConstraintItem;
}

export interface CriteriaConstraintDTO {
    [key: string]: CriteriaConstraintItem;
}

export interface RankFilters {
    id: string;
    criteria: {
        id: string;
        name: string;
        importance: number;
        value: number | string | { name: string, value: number }[];
    }[];
    constraints: CriteriaConstraintDTO | null;
}

// const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

const baseUrl = getEnvironmentVariable('REACT_APP_IMAGE_BASE_URL');
const IMAGE_BASE_URL = `${baseUrl}/img/appicons`;
export const DEFAULT_IMAGE_URL = `${IMAGE_BASE_URL}/398.png`;

const AUTH_TOKEN = getEnvironmentVariable('REACT_APP_API_TOKEN');
const LAST_OLD_DECISION_ID = 249;

const _transformDecisionImageUrl = (imageUrl: string, id: number): string => {
    if (!imageUrl) {
        if (id <= LAST_OLD_DECISION_ID) {
            return `${IMAGE_BASE_URL    }/${id}.png`;
        }
        return DEFAULT_IMAGE_URL;
    }

    let icon = `${IMAGE_BASE_URL}/${imageUrl}`;
    if (!icon.endsWith('.png')) {
        icon += '.png';
    }
    return icon;
};
const _transformCriteriaFields = (fields: any[]): any[] => {
    return fields.map(field => {
        const icon = field.icon ? `${IMAGE_BASE_URL}/${field.icon}` : DEFAULT_IMAGE_URL;

        return {
            ...field,
            icon,
        }
    });
};

const _transformDecisions = (decisions: DecisionsResponse['data']): Decision[] => {
    return decisions.map(decision => {
        return {
            id: decision.id,
            name: decision.name,
            author: decision.user,
            image: _transformDecisionImageUrl(decision.icon, decision.id),
        }
    })
};

const _generatePaginationQuery = (pageDetails: PaginationInfo) => {
    return `page_size=${pageDetails.pageSize}&current_page=${pageDetails.currentPage}`;
}

export const getPopularDecisions = async (pageDetails: PaginationInfo = {currentPage: 1, pageSize: 10}) => {
    try {
        const paginationQuery = _generatePaginationQuery(pageDetails);
        const response = await axiosInstance.get<DecisionsResponse>(`decisions/list/popular?${paginationQuery}`);
        return {
            decisions: _transformDecisions(response.data.data),
            currentPage: response.data.current_page,
            pageSize: response.data.page_size,
            total: response.data.total,
            totalPages: response.data.total_pages,
        };
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getTrendingDecisions = async (pageDetails: PaginationInfo = {currentPage: 1, pageSize: 10}) => {
    try {
        const paginationQuery = _generatePaginationQuery(pageDetails);
        const response = await axiosInstance.get<DecisionsResponse>(`decisions/list/trend?${paginationQuery}`);
        return {
            decisions: _transformDecisions(response.data.data),
            currentPage: response.data.current_page,
            pageSize: response.data.page_size,
            total: response.data.total,
            totalPages: response.data.total_pages,
        };
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getDecisionsByCategory = async (params: PaginationInfo & { category: string } = {
    currentPage: 1,
    pageSize: 10,
    category: ''
}) => {
    try {
        const paginationQuery = _generatePaginationQuery(params);
        const response = await axiosInstance.get<DecisionsResponse>(
            `/decisions/list/bycategory?category=${params.category}&${paginationQuery}`
        );
        return {
            decisions: _transformDecisions(response.data.data || []),
            currentPage: response.data.current_page,
            pageSize: response.data.page_size,
            total: response.data.total,
            totalPages: response.data.total_pages,
        };
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getMyDecisions = async () => {
    try {
        const response = await axiosInstance.get(`/decisions/list/my`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getFriendsDecisions = async () => {
    try {
        const response = await axiosInstance.get(`decisions/list/friends`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getDecisionCriteria = async (id: number | string) => {
    try {
        const response = await axiosInstance.get(`/criteria?app_id=${id}`, {
            headers: {
                'Authorization': AUTH_TOKEN,
            }
        });

        const fields = response.data.fields.filter((item: {
            technicalField: string
        }) => item?.technicalField !== 'true' && item?.technicalField !== '1');

        const technoFields = response.data.fields.filter((item: {
            technicalField: string
        }) => item.technicalField === 'true' || item?.technicalField !== '1');

        return {
            ...response.data,
            fields: _transformCriteriaFields(fields),
            technoFields,
            icon: _transformDecisionImageUrl(response.data.icon, response.data.id),
        };
    } catch (error) {
        console.error('Cannot get decisions:', error);
        throw error;
        // return mockAppCriteria;
    }
};

export const rankApps = async ({
    id,
    criteria,
    constraints
}: RankFilters, page_size: number, page: number): Promise<any> => {
    try {
        const body: { criteria: RankFilters['criteria'], constraints?: RankFilters['constraints'] } = {
            criteria
        };
        if (constraints && Object.keys(constraints).length > 0) {
            body['constraints'] = constraints;
        }

        const response = await axiosInstance.post(`/rank?app_id=${id}&page_size=${page_size}&current_page=${page}`, body, {
            headers: {
                'Authorization': AUTH_TOKEN,
            }
        });

        const results = response.data.results.map((data: any) => ({
            ...data,
            icon: _transformDecisionImageUrl(data.icon, data.id),
        }));

        const criteriaKey = Object.keys(response.data.criteria);
        const criteriaData: any[] = [];
        criteriaKey.filter((criteriaKey: any) => /^f\d+$/.test(criteriaKey)).forEach(key => {
            criteriaData.push(response.data.criteria[key])
        });

        return {
            ...response.data,
            criteria: criteriaData,
            results,
        };
    } catch (error) {
        console.error('Cannot get the results:', error);
        throw error;
        // return mockAppResults.map((data: any) => ({
        //     ...data,
        //     icon: _transformDecisionImageUrl(data.icon),
        // }));
    }
};

export const rerankApps = async ({
    id,
    criteria,
    constraints
}: RankFilters, rerank: string, page_size: number, page: number): Promise<any> => {
    const body: { criteria: RankFilters['criteria'], constraints?: RankFilters['constraints'] } = {
        criteria
    };
    if (constraints && Object.keys(constraints).length > 0) {
        body['constraints'] = constraints;
    }
    try {
        const response = await axiosInstance.post(`/rerank?app_id=${id}&rerank=${rerank}&page_size=${page_size}&current_page=${page}`, body, {
            headers: {
                'Authorization': AUTH_TOKEN,
            }
        });

        const results = response.data.results.map((data: any) => ({
            ...data,
            icon: _transformDecisionImageUrl(data.icon, data.id),
        }));

        const criteriaData: { [key: string]: string } = {};
        const criteriaKey = Object.keys(response.data.criteria);
        criteriaKey.filter((criteriaKey: any) => /^f\d+$/.test(criteriaKey)).forEach(key => {
            criteriaData[key] = response.data.criteria[key].importance;
        });

        return {
            ...response.data,
            criteria: criteriaData,
            results,
        };
    } catch (error) {
        console.error('Cannot get the results:', error);
        throw error;
    }
};
