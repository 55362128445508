import axios from 'axios';
// import {toast} from "react-toastify";
import {getEnvironmentVariable} from "../utils";

const BASE_URL = getEnvironmentVariable('REACT_APP_API_URL');

const axiosInstance = axios.create({
    baseURL: BASE_URL, // Replace with your API base URL
    headers: {
        'Content-Type': 'application/json',
    },
});

// TODO Implement interceptors
// axiosInstance.interceptors.request.use(
//     (config) => {
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );
//
axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        console.error('API call error:', error);
        // toast.error('API call error: ' + error.message);
        return Promise.reject(error);
    }
);

export default axiosInstance;
