import React, {useState} from 'react';
import {getEnvironmentVariable} from "../../utils";
import headerLogo from "../../assets/images/slogo.svg";
import {Link, useNavigate} from "react-router-dom";

import './header.scss';

const headerLinks = [
    {label: 'Terms of use', link: 'https://www.sparkdit.com/legal/terms-of-use'},
    {label: 'Features', link: 'https://ai.sparkdit.com/webpages/view/1'},
    {label: 'Support', link: 'https://ai.sparkdit.com/webpages/view/3'},
    {label: 'Pricing', link: 'https://ai.sparkdit.com/webpages/view/4'},
];

const platformURL = getEnvironmentVariable('REACT_APP_PLATFORM_URL');
const LOGIN_URL = `${platformURL}/login`;
const REGISTRATION_URL = `${platformURL}/register`;

const Header: React.FC = () => {
    const navigate = useNavigate();
    const [mobileNavActive, setMobileNavActive] = useState(false);

    const toggleMobileNav = () => {
        setMobileNavActive(!mobileNavActive);
    };

    return (
        <header className="header">
            <div className="header__logo">
                <img src={headerLogo} alt="logo" onClick={() => navigate('/')}/>
            </div>
            <nav className={`header__nav ${mobileNavActive ? 'active' : ''}`}>
                {headerLinks.map(item => (
                    <Link key={item.label} to={item.link} className="header__link">{item.label}</Link>
                ))}
                <div className="header__buttons">
                    <>
                        <Link
                            className="header__button header__button--signup"
                            to={REGISTRATION_URL}
                        >
                            Sign up
                        </Link>
                        <Link
                            className="header__button header__button--login"
                            to={LOGIN_URL}
                        >
                            Login
                        </Link>
                    </>
                </div>
            </nav>
            {/*<iframe*/}
            {/*    style={{ display: "none" }}*/}
            {/*    title='-'*/}
            {/*    ref={iframeRef}*/}
            {/*    src='https://platform.sparkdit.com/auth'*/}
            {/*    onLoad={onIframeLoad}*/}
            {/*/>*/}
            {/*<div className="header__menu-icon" onClick={toggleMobileNav}>☰</div>*/
            }
            <div className="header__menu-icon" onClick={toggleMobileNav}>
                {mobileNavActive ? <>&times;</> : <>&#9776;</>}
            </div>
        </header>
    );
};

export default Header;
