import {CriteriaConstraintField, CriteriaConstraintItem, RankFilters} from "../api/decisions.api";
import {AppCriteria, CriteriaConstraint} from "../types/decisions";

type EnvironmentVariableName = 'REACT_APP_API_URL' |
    'REACT_APP_API_TOKEN' |
    'REACT_APP_PLATFORM_URL' |
    'REACT_APP_IMAGE_BASE_URL' |
    'REACT_APP_VIRTUAL_AGENT_KEY' |
    'REACT_APP_VIRTUAL_AGENT_SERVER_URL' |
    'REACT_APP_AWS_REGION' |
    'REACT_APP_AWS_ACCESS_KEY_ID' |
    'REACT_APP_AWS_SECRET_ACCESS_KEY'
    ;

export const scrollToAnchor = (hash: string) => {
    const element = document.getElementById(hash);
    element && element.scrollIntoView({behavior: 'smooth'});
}

const envVars = {
    'REACT_APP_API_URL': 'https://api.sparkdit.com',
    'REACT_APP_API_TOKEN': 'c8408289da0e3d3d7e1591786aed70c051d57dc2',
    'REACT_APP_PLATFORM_URL': 'https://platform.sparkdit.com',
    'REACT_APP_VIRTUAL_AGENT_SERVER_URL': 'https://api.heygen.com',
    'REACT_APP_IMAGE_BASE_URL': 'https://storage.sparkdit.com',
    'REACT_APP_AWS_REGION': 'us-east-2',
    'REACT_APP_AWS_ACCESS_KEY_ID': 'AKIA5EG3UP4NJVRNTX7A',
    'REACT_APP_AWS_SECRET_ACCESS_KEY': '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',
    'REACT_APP_VIRTUAL_AGENT_KEY': 'M2RlYzljZDlmNTE0NGM2Y2JmODk4ZDY5MzhhZjk4YWYtMTcyNDgzODUxNA==',
}

export const getEnvironmentVariable = (name: EnvironmentVariableName): string => {
    // return process.env[name] || '';
    return envVars[name] || '';
}

export const getRandomItem = (reactions: string[]): string => {
    return reactions[Math.floor(Math.random() * reactions.length)];
}

const getFieldValueByType = (field: any) => {
    switch (field.type) {
        case 'bool':
            return Object.keys(field.val).find(key => field.val[key] !== -1);
        case 'select':
        case 'multiselect':
            return Object.keys(field.val).map(key => ({
                name: key,
                // @ts-ignore
                value: +field.val[key],
            }));
        case 'zipcode':
            return [0, 10, 20, 555].join(',');
        default:
            return field.val;
    }
}

const constructConstraintRules = (items: CriteriaConstraint[]): CriteriaConstraintField | CriteriaConstraintItem => {
    const item = items.shift() as CriteriaConstraint;
    if (items.length < 1) {
        return {
                rule: item.rule,
                value: item.value,
        }
    }

    return {
        operator: items[0].operator,
        left: {
            rule: item.rule,
            value: item.value,
        },
        right: constructConstraintRules(items),
    };
};

export const transformCriteriaData = (criteria: AppCriteria, constraints: { [key: string]: CriteriaConstraint[] }): RankFilters => {
    let transformedConstraints: RankFilters['constraints'] | null = {};
    const keys = Object.keys(constraints);
    if (keys.length < 1) {
        transformedConstraints = null;
    } else {
        keys.forEach(key => {
            if (transformedConstraints && constraints[key].length > 0) {
                transformedConstraints[key] = constructConstraintRules(constraints[key]) as CriteriaConstraintItem;
            }
        });
    }

    const filteredCriteria = criteria.fields.filter(item => item.val !== undefined && item.val !== '').map(field => {
        return {
            id: field.id,
            name: field.name as string,
            importance: +field.importance,
            value: getFieldValueByType(field),
        };
    });

    return {
        id: criteria.id,
        criteria: filteredCriteria,
        constraints: transformedConstraints,
    }
};

export const getFieldName = (data: string | { value: string }): string => {
    if (typeof data === 'string') {
        return data;
    }

    return data.value;
};

export const getCriteriaNameValuePairs = (data: any): { name: string, value: string } => {
    const res = {
        name: data.name,
        value: ''
    };

    if (data.hasOwnProperty('value')) {
        res.value = data.value;
    } else {
        const keys = Object.keys(data.val);
        if (keys.length) {
            res.value = data.val[keys[0]];
        }
    }

    return res;
};
