import React from 'react';
import {Button, Pagination} from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {AppSkinProps} from "../../../types/decisions";
import Modal from "../../modal/Modal";
import DraggableDiv from "../../draggableDiv/DraggableDiv";
import CriteriaSetting from "./CriteriaSettings";

const AppSkin6 = ({
    results,
    onRerank,
    criteriaLoading,
    criteriaData,
    onSliderChange,
    onCriteriaApply,
    resultLoading,
    onPageChange,
    pageInfo,
    Loading,
    onTradeoffValueChange,
    fieldInitialValues,
}: AppSkinProps) => {
    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState<React.ReactElement>(<></>);
    const handleClose = () => {
        setModalContent(<></>);
        setOpen(false);
    }

    const onCriteriaSettingsOpen = (idx: number) => {
        setOpen(true);
        setModalContent(
                <CriteriaSetting
                        field={criteriaData.fields[idx]}
                        index={idx}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        fieldInitialValues={fieldInitialValues}
                        handleModalClose={handleClose}
                        areCTAsDisplayed
                />
        );
    };

    return (
            <div className='application skin6'>
                {criteriaLoading ? (
                        <Loading/>
                ) : (
                        <>
                            <div className='application-data'>
                                <img src={criteriaData.icon} alt={criteriaData.name}/>
                                <div className='app-info'>
                                    <h1 className='app-name'>{criteriaData.name}</h1>
                                    <div className='app-description'>{criteriaData.description}</div>
                                </div>
                            </div>
                            <div className='application-details'>
                                <div className='app-criteria'>
                                    {criteriaLoading ? (
                                            <Loading/>
                                    ) : (
                                            <div className='app-criteria-draggable-cards'>
                                                <DraggableDiv
                                                        initialCriteriaFields={criteriaData.fields}
                                                        onCriteriaApply={onCriteriaApply}
                                                        onTradeoffValueChange={onTradeoffValueChange}
                                                        onSliderChange={onSliderChange}
                                                        onCriteriaSettingsOpen={onCriteriaSettingsOpen}
                                                />
                                            </div>
                                    )}
                                </div>
                                <div className='app-results skin6-products'>
                                    {resultLoading ? (
                                            <Loading/>
                                    ) : (
                                            <>
                                                <h3>Results ({pageInfo.total})</h3>
                                                <div>
                                                    <div className='results-skin4'>
                                                        {results.length === 0 ? (
                                                                <div style={{color: '#676767'}}>No products found.</div>
                                                        ) : results.map((result, index) => (
                                                                <div className='app-results-item' key={result.id}>
                                                                    <div className="image-container"
                                                                         style={{backgroundImage: `url('${result.icon}')`}}/>
                                                                    <div className='app-criteria-item-details'>
                                                                        <div className='app-criteria-item-details-rank'>
                                                                            <div className='app-criteria-item-details-rank-bar'
                                                                                 style={{width: result.rank + '%'}}/>
                                                                            <div className='app-criteria-item-details-rank-rate'>
                                                                                {result.rank === '-' ? <>&nbsp;</> : result.rank + '%'}
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <h3
                                                                                    className='truncate'
                                                                                    title={typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}
                                                                            >
                                                                                {typeof result.data.name !== 'object' ? result.data.name : result.data.name.value}
                                                                            </h3>
                                                                            <div className='app-criteria-item-details-specs'>
                                                                                {Object.keys(result.data).map((key) => {
                                                                                    if (!/^f\d+$/.test(key)) {
                                                                                        return null;
                                                                                    }
                                                                                    // @ts-ignore
                                                                                    const {name, value} = result.data[key];
                                                                                    return (
                                                                                            <div
                                                                                                    className='app-criteria-item-details-specs-spec'
                                                                                                    key={result.id + key}
                                                                                            >
                                                                                                <span>{name}</span>
                                                                                                <span>
                                                                                                     {typeof value === 'object' ? (
                                                                                                             <>{Object.keys(value)
                                                                                                             .map((key) => (
                                                                                                                     <span>{key} {value[key]}
                                                                                                                         <br/></span>
                                                                                                             ))}</>
                                                                                                     ) : (
                                                                                                             value
                                                                                                     )}
                                                                                                </span>
                                                                                            </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                        <div className='app-criteria-item-details-ctas'>
                                                                            {index !== 0 && (
                                                                                    <Button
                                                                                            className='app-criteria-item-details-ctas-cta'
                                                                                            variant='outlined'
                                                                                            onClick={() => onRerank(result.id)}
                                                                                            startIcon={
                                                                                                <ArrowUpwardIcon/>
                                                                                            }
                                                                                    />
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className='app-results-page-info'>
                                                    {/*Displayed products: <b>{pageInfo.start}</b> to <b>{pageInfo.end}</b> of <b>{pageInfo.total}</b>.*/}
                                                    <Pagination
                                                            page={pageInfo.current_page}
                                                            count={pageInfo.total_pages} shape="rounded"
                                                            onChange={(event, page) => onPageChange(page)}
                                                    />
                                                </div>
                                            </>
                                    )}
                                </div>
                            </div>
                        </>
                )}
                <Modal isOpen={open} handleClose={handleClose}>
                    {modalContent}
                </Modal>
            </div>
    );
};

export default AppSkin6;