import React, {useEffect, useState} from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Button, ButtonGroup, Collapse, Pagination, Typography} from "@mui/material";
import {AppResultInfo, AppSkinProps} from "../../../types/decisions";
import {getCriteriaNameValuePairs, getFieldName} from "../../../utils";
import Image from "../../image/Image";
import {
  AppCriteriaTypeNumber,
  AppCriteriaTypeSelect,
  AppCriteriaTypeText,
  AppCriteriaTypeZip,
  transformCriteriaObjectToArray
} from "./TrandeoffCriteriaFields";

const AppSkin1 = ({
  results,
  criteriaData,
  resultLoading,
  Loading,
  onResetTradeOffs,
  onRerank,
  onCriteriaApply,
  onSliderChange,
  onTradeoffValueChange,
  fieldInitialValues,
  pageInfo,
  onPageChange,
  criteriaConstraints,
  setCriteriaConstraints,
}: AppSkinProps) => {
  const [mode, setMode] = useState<'basic' | 'default' | 'advanced'>('default');
  const [displayedResult, setDisplayedResult] = useState<AppResultInfo | null>(null);
  const [firstResultSpecs, setFirstResultSpecs] = useState<{ name: string, value: string }[]>([]);
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const specsToDisplay = expanded ? firstResultSpecs : firstResultSpecs.slice(0, 3);

  useEffect(() => {
    setDisplayedResult(results[0])
  }, [results]);

  useEffect(() => {
    if (!displayedResult) {
      return;
    }

    const specKeys = Object.keys(displayedResult.data);
    // @ts-ignore
    const specs = specKeys.map((specKey) => getCriteriaNameValuePairs(displayedResult.data[specKey]))

    setFirstResultSpecs(specs);
  }, [displayedResult]);

  if (resultLoading) {
    return <Loading/>;
  }

  if (!results.length) {
    return null;
  }

  return (
    <div className='application'>
      <div className='results-skin1'>
        <div className='results-skin1-left'>
          <div className='application-data'>
            <img src={criteriaData.icon} alt={criteriaData.name}/>
            <div className='app-info'>
              <h1 className='app-name'>{criteriaData.name}</h1>
              <div className='app-description'>{criteriaData.description}</div>
            </div>
          </div>
          <div className='results-skin1-left-app-settings'>
            {criteriaData.fields.map((field, index) => {
              if (field.type === 'text' || field.type === 'dynamic') {
                return (
                  <div key={field.id} className='results-skin1-left-app-settings-item'>
                    <div className='left'>
                      <div className='title'>{index + 1}. {getFieldName(field.name)}</div>
                      {mode === 'basic' ? null : (
                        <div className='sub-title'>
                          How important is {getFieldName(field.name)}
                        </div>
                      )}
                    </div>
                    <div className='right'>
                      <AppCriteriaTypeText
                        value={field.val as string}
                        importance={+field.importance}
                        hideValues={mode === 'basic'}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        idx={index}
                        id={field.id}
                        showAddConstraint={mode === 'advanced'}
                        criteriaConstraints={criteriaConstraints}
                        setCriteriaConstraints={setCriteriaConstraints}
                      />
                    </div>
                  </div>
                );
              } else if (field.type === 'number') {
                return (
                  <div key={field.id} className='results-skin1-left-app-settings-item'>
                    <div className='left'>
                      <div className='title'>{index + 1}. {getFieldName(field.name)}</div>
                      {mode === 'basic' ? null : (
                        <div className='sub-title'>
                          How important is {getFieldName(field.name)}
                        </div>
                      )}
                    </div>
                    <div className='right'>
                      <AppCriteriaTypeNumber
                        value={+field.val}
                        importance={+field.importance}
                        min={+field.min}
                        max={+field.max}
                        hideValues={mode === 'basic'}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        idx={index}
                        id={field.id}
                        showAddConstraint={mode === 'advanced'}
                        setCriteriaConstraints={setCriteriaConstraints}
                        criteriaConstraints={criteriaConstraints}
                      />
                    </div>
                  </div>
                );
              } else if (
                field.type === 'select'
                || field.type === 'bool'
              ) {
                return (
                  <div key={field.id} className='results-skin1-left-app-settings-item'>
                    <div className='left'>
                      <div className='title'>{index + 1}. {getFieldName(field.name)}</div>
                      {mode === 'basic' ? null : (
                        <div className='sub-title'>
                          How important is {getFieldName(field.name)}
                        </div>
                      )}
                    </div>
                    <div className='right'>
                      <AppCriteriaTypeSelect
                        items={transformCriteriaObjectToArray(field.val)}
                        importance={+field.importance}
                        hideValues={mode === 'basic'}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        initialValues={fieldInitialValues[index].val as {
                          [key: string]: string
                        }}
                        idx={index}
                        id={field.id}
                        showAddConstraint={mode === 'advanced'}
                        criteriaConstraints={criteriaConstraints}
                        setCriteriaConstraints={setCriteriaConstraints}
                      />
                    </div>
                  </div>
                )
              } else if (field.type === 'zipcode') {
                return (
                  <div key={field.id} className='results-skin1-left-app-settings-item'>
                    <div className='left'>
                      <div className='title'>{index + 1}. {getFieldName(field.name)}</div>
                      {mode === 'basic' ? null : (
                        <div className='sub-title'>
                          How important is {getFieldName(field.name)}
                        </div>
                      )}
                    </div>
                    <div className='right'>
                      <AppCriteriaTypeZip
                        importance={+field.importance}
                        hideValues={mode === 'basic'}
                        onSliderChange={onSliderChange}
                        onTradeoffValueChange={onTradeoffValueChange}
                        idx={index}
                        showAddConstraint={mode === 'advanced'}
                      />
                    </div>
                  </div>
                )
              }

              return null;
            })}
          </div>
        </div>
        <div className='results-skin1-right'>
          {displayedResult ? (
            <div className='results-skin1-right-first-result'>
              <Image src={displayedResult.icon} alt={displayedResult.id}
                     className='results-skin1-right-first-result-image'/>
              <div className='results-skin1-right-first-result-title truncate'>
                {getFieldName(displayedResult.data.name)}
              </div>
              <div className='results-skin1-right-first-result-specs'>
                <div style={{padding: 8, marginTop: 16}}>
                  {specsToDisplay.map(item => (
                    <div className='spec' key={item.name}
                         style={{padding: '4px 0 0 0', display: 'flex', justifyContent: 'space-between'}}>
                      <Typography variant="subtitle1" color="textSecondary">{item.name}</Typography>
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        style={{maxWidth: '50%', overflow: 'hidden', whiteSpace: 'nowrap'}}
                        className='truncate'
                        title={item.value}
                      >
                        {item.value}
                      </Typography>
                    </div>
                  ))}
                  <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {firstResultSpecs.slice(3).map(item => (
                      <div className='spec' key={item.name}
                           style={{padding: '4px 0 0 0', display: 'flex', justifyContent: 'space-between'}}>
                        <Typography variant="subtitle1" color="textSecondary">{item.name}</Typography>
                        <Typography
                          variant="body1"
                          fontWeight="bold"
                          style={{maxWidth: '50%', overflow: 'hidden', whiteSpace: 'nowrap'}}
                          className='truncate'
                          title={item.value}
                        >
                          {item.value}
                        </Typography>
                      </div>
                    ))}
                  </Collapse>
                  <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                      variant='text'
                      onClick={toggleExpanded}
                      sx={{marginTop: 2}}
                      endIcon={expanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                      size='small'
                    >
                      {expanded ? 'Show Less' : 'Show More'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className='results-skin1-right-results'>
            <div className='results-skin1-right-results-title'>All results</div>
            {results.map((result, index) => (
              <div
                key={result.id}
                className='results-skin1-right-results-item'
                onClick={() => setDisplayedResult(result)}
              >
                <div>
                  <Image width={20} src={result.icon} alt='logo'/>
                </div>
                <div className='text truncate'>
                  {((pageInfo.current_page - 1) * pageInfo.page_size + index + 1) + '. ' + getFieldName(result.data.name)}
                </div>
                <div className='rank'>
                  {result.rank !== '-' ? result.rank + '%' : ''}
                  {index !== 0 ?
                    <ArrowUpwardIcon
                      fontSize='small'
                      className='arrow-up'
                      onClick={() => onRerank(result.id)}
                    />
                    : null}
                </div>
              </div>
            ))}
            <div className='app-results-page-info' style={{marginTop: 16}}>
            <Pagination
                page={pageInfo.current_page}
                count={pageInfo.total_pages}
                shape="rounded"
                size='small'
                onChange={(event, page) => onPageChange(page)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='app-actions'>
        <ButtonGroup variant="outlined" aria-label="Basic button group" className='app-actions-mode-buttons'>
          <Button onClick={() => setMode('basic')}
                  variant={mode === 'basic' ? 'contained' : 'outlined'}>Basic</Button>
          <Button onClick={() => setMode('default')}
                  variant={mode === 'default' ? 'contained' : 'outlined'}>Default</Button>
          <Button onClick={() => setMode('advanced')}
                  variant={mode === 'advanced' ? 'contained' : 'outlined'}>Advanced</Button>
        </ButtonGroup>
        <Button style={{marginLeft: 20}} onClick={onResetTradeOffs} variant='outlined'>Reset</Button>
        {/*
                // @ts-ignore */}
        <Button style={{marginLeft: 20}} onClick={onCriteriaApply} variant='contained'>Decide</Button>
      </div>
    </div>
  );
};

export default AppSkin1;