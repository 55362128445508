import React, {useEffect, useRef, useState} from 'react';
import {Resizable, ResizeCallbackData} from 'react-resizable';
import './ResizableDivs.css';
import {AppCriteriaField, AppSkinProps} from "../../types/decisions";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Image from "../image/Image";

interface CriteriaItem extends AppCriteriaField {
    name: string;
    height: number;
    width: number;
    isHidden: boolean;
    importance: number;
}

interface Props {
    initialCriteriaFields: AppCriteriaField[];
    onCriteriaApply: () => void;
    onTradeoffValueChange: (newValue: number, idx: number) => void,
    onSliderBulkChange: AppSkinProps['onSliderBulkChange'],
    onCriteriaSettingsOpen: (index: number) => void,
    criteriaData: AppSkinProps['criteriaData'];
}

const MIN_VISIBLE_PERCENTAGE = 7;
const POPUP_PERCENTAGE = 30;

const generateCriteriaInitialInfo = (initialCriteriaFields: AppCriteriaField[], containerWidth: number): CriteriaItem[] => {
    const visibleFields = initialCriteriaFields.filter(item => item.hide_importance !== '1');
    const totalImportanceRatio = visibleFields.reduce((acc, item) => acc + +item.importance, 0) / 100;

    return visibleFields.map((item) => {
        const importance = Math.round(+item.importance / totalImportanceRatio);
        return {
            ...item,
            name: typeof item.name === 'object' ? item.name.value : item.name,
            height: 120,
            width: importance * containerWidth / 100,
            isHidden: importance < MIN_VISIBLE_PERCENTAGE,
            importance: importance,
        }
    });
};

const ResizableDivs = ({
    onSliderBulkChange,
    onCriteriaApply,
    onCriteriaSettingsOpen,
    criteriaData,
}: Props) => {
    const [criteria, setCriteria] = useState<CriteriaItem[]>([]);
    const [poppedUpIds, setPoppedUpIds] = useState<string[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const items = generateCriteriaInitialInfo(criteriaData.fields, containerRef.current?.clientWidth || 1000);
        setCriteria(items);
        const importantCriteria: string[] = [];
        items.forEach(item => {
            if (item.importance > POPUP_PERCENTAGE) {
                importantCriteria.push(item.id);
            }
        });
        setPoppedUpIds(importantCriteria);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [criteriaData.fields]);

    const onBoxResize = (size: ResizeCallbackData["size"], id: string) => {
        const {width} = size;
        const importance = Math.round(width * 100 / (containerRef.current?.clientWidth || 1000));

        setCriteria(sizes => sizes.map((item) => {
            if (item.id === id) {
                if (importance >= POPUP_PERCENTAGE && !poppedUpIds.includes(item.id)) {
                    setPoppedUpIds(ids => [...ids, item.id]);
                }

                return {
                    ...item,
                    width,
                    importance,
                    isHidden: importance < MIN_VISIBLE_PERCENTAGE,
                }
            }

            return item;
        }));
    };

    const onBoxResizeStop = () => {
        const containerWidth = containerRef.current?.clientWidth || 1000;
        const elementSizes: { [key: string]: number } = {};
        containerRef.current?.childNodes.forEach((childNode) => {
            // @ts-ignore
            const elementWidth: number = childNode.clientWidth || 0;
            // @ts-ignore
            const elementId = childNode.dataset.elementid;
            elementSizes[elementId] = Math.round(elementWidth * 100 / containerWidth);
        });

        onSliderBulkChange(elementSizes);
        onDecide();
    };

    const onOpenSettings = (id: string) => {
        const index = criteria.findIndex(item => item.id === id);
        if (index !== -1) {
            onCriteriaSettingsOpen(index);
        }
    }

    const makeCriteriaVisible = (id: string) => {
        setCriteria(criteria => criteria.map((item) => {
            if (id !== item.id) {
                return item;
            }

            return {
                ...item,
                importance: MIN_VISIBLE_PERCENTAGE,
                width: (containerRef.current?.clientWidth || 1000) * MIN_VISIBLE_PERCENTAGE / 100,
                isHidden: false,
            }
        }));
    };

    const visibleCriteria = criteria.filter(item => !item.isHidden);
    const hiddenCriteria = criteria.filter(item => item.isHidden);

    function onDecide() {
        onCriteriaApply();
    }

    return (
            <div>
                <div className="layoutRoot" ref={containerRef}>
                    {visibleCriteria.map((item) => (
                            <Resizable
                                    key={item.id}
                                    className='box'
                                    height={item.height}
                                    width={item.width}
                                    onResize={(_, data) => onBoxResize(data.size, item.id)}
                                    onResizeStop={onBoxResizeStop}
                                    resizeHandles={['e', 'w']}
                                    axis='both'
                            >
                                <div data-elementid={item.id} style={{width: item.width + '%', height: item.height + 'px'}}>
                                    <h3 className='importance-card-title'>
                                        <span style={{fontSize: item.importance > 20 ? 18.75 : 14}}>{item.name}</span>
                                        <IconButton style={{marginLeft: '.5rem'}} onClick={() => onOpenSettings(item.id)}>
                                            <EditIcon color='primary' fontSize='small'/>
                                        </IconButton>
                                    </h3>
                                    <Image src={item.icon} className='app-criteria-icon'/>
                                </div>
                            </Resizable>
                    ))}
                </div>
                <div className='skin5-bottom-actions'>
                    <div className='hidden-elements'>
                        <div className="hidden-elements-items-container">
                            {/*<h3>Hidden criteria</h3>*/}
                            <div>
                                {hiddenCriteria.map((item) => (
                                    <span
                                            key={item.id}
                                            className='hidden-elements-item'
                                            onClick={() => makeCriteriaVisible(item.id)}
                                            title='Click to make it visible'
                                    >
                                        {item.name}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/*<div className='skin-5-app-actions'>*/}
                    {/*    <Button*/}
                    {/*            variant='contained'*/}
                    {/*            className='decide-btn'*/}
                    {/*            onClick={onDecide}*/}
                    {/*    >*/}
                    {/*        Decide*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
            </div>
    );
};

export default ResizableDivs;
