import React from 'react';
import {useNavigate} from "react-router-dom";
import {Decision} from "../../types/decisions";
import Image from "../image/Image";
import './productCart.scss';

interface ProductCartProps {
    product: Decision;
}

const appDefaultUrl = window.location.hostname.includes('maria') ? '/virtual-agent' : '/apps';

const ProductCart: React.FC<ProductCartProps> = ({product}) => {
    const navigate = useNavigate();

    const onProductClick = () => {
        navigate(`${appDefaultUrl}/${product.id}`);
    };

    return (
        <div className="product-cart">
            <Image src={product.image} alt={product.name} className="product-image" onClick={onProductClick}/>
            <div className='product-details'>
                <p className="product-name" onClick={onProductClick} title={product.name}>{product.name}</p>
                <p className="product-description" title={product.author}>By {product.author}</p>
            </div>
        </div>
    );
}

export default ProductCart;
