import React from 'react';
import DEFAULT_IMAGE from "./defaultImage.png";

const Image = (props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {
    const onError = (e: any) => {
        e.target.src = DEFAULT_IMAGE;
    }
    return (
        <img alt='Sparkdit' {...props} onError={onError}/>
    );
};

export default Image;