import React from "react";
import {default as MuiSlider} from '@mui/material/Slider';
import {SliderProps} from "@mui/material/Slider/Slider";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import Box from "@mui/material/Box";
import {IconButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

type SliderCustomProps = SliderProps & {
    hideThumbButtons?: boolean;
    leftText?: string;
    rightText?: string;
    isEditable?: boolean;
    onEditIconClick?: () => void;
}

const Slider = ({
    hideThumbButtons = false,
    leftText = '',
    rightText = '',
    isEditable = false,
    onEditIconClick,
    ...props
}: SliderCustomProps) => {
    const showHelpTexts = leftText?.trim() && rightText?.trim();

    return (
        <>
            <Box display="flex" alignItems="center">
                {!hideThumbButtons ?
                    <ThumbDownIcon fontSize='small' style={{marginRight: '1rem'}} color='primary'/> : null}
                <MuiSlider
                    defaultValue={50}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                    {...props}
                />
                {!hideThumbButtons ?
                    <ThumbUpIcon fontSize='small' style={{marginLeft: '1rem'}} color='primary'/> : null}
                {isEditable ? (
                    <IconButton style={{marginLeft: '.5rem'}} onClick={onEditIconClick}>
                        <EditIcon color='primary' fontSize='small'/>
                    </IconButton>
                ) : null}
            </Box>
            {showHelpTexts ? (
                <div
                    style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: 'small'}}>
                    <span>{leftText}</span>
                    <span>{rightText}</span>
                </div>
            ) : null}
        </>
    );
};

export default Slider;