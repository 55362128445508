import React, {useEffect, useRef, useState} from 'react';
import debounce from 'debounce';
import {getDecisionsByCategory, getPopularDecisions, getTrendingDecisions} from "../../api/decisions.api";
import {Decision} from "../../types/decisions";
import {AxiosError} from "axios";
import Skeleton from "react-loading-skeleton";
import Swiper from "../swiper/Swiper";
import {scrollToAnchor} from "../../utils";
import './applications.scss';

const renderSkeleton = (skeletonsCount: number = 4) => {
    return Array.from({length: skeletonsCount}, (_, index) => (
        <div className='applications-skeleton-wrapper' key={index}>
            <Skeleton width={280} height={120}/>
        </div>
    ));
};

const DecisionSection = ({title, isLoading, decisions, id, total, onReachEnd}: {
    title: string,
    isLoading: boolean,
    decisions: Decision[],
    id: string;
    total: number;
    onReachEnd: () => void;
}) => {
    if (!decisions.length) {
        return null;
    }

    return (
        <div id={id}>
            {isLoading ? <Skeleton width={240} height={32} className='applications-skeleton-title'/> : (
                <h3>{title} ({total})</h3>
            )}
            <div className='applications-container'>
                {isLoading ? renderSkeleton(4) : (
                    <>
                        {decisions.length > 0
                            ? <Swiper items={decisions} onReachEnd={onReachEnd}/>
                            : <div>No {title} decisions found.</div>
                        }
                    </>
                )}

            </div>
        </div>
    )
};

const categories = ['eCommerce', 'Healthcare', 'Financial', 'Industry', 'Government', 'Personal'];

const Applications = () => {
    const [isPopularLoading, setPopularLoading] = useState(true);
    const [isTrendingLoading, setTrendingLoading] = useState(true);
    const [isCategoryLoading, setCategoryLoading] = useState(true);
    const [popularDecisions, setPopularDecisions] = useState<Decision[]>([]);
    const [trendingDecisions, setTrendingDecisions] = useState<Decision[]>([]);
    const [categoryDecisions, setCategoryDecisions] = useState<{
        [key: string]: {
            decisions: Decision[],
            currentPage: number,
            pageSize: number,
            total: number,
            totalPages: number,
        }
    }>({eCommerce: {decisions: [], currentPage: 0, pageSize: 0, total: 0, totalPages: 1}});
    const searchTerm = useRef<HTMLInputElement>(null);
    const [popularPageDetails, setPopularPageDetails] = useState<{
        currentPage: number;
        pageSize: number;
        total: number;
        totalPages: number;
    }>({currentPage: 1, pageSize: 60, total: 0, totalPages: 1});
    const [trendingPageDetails, setTrendingPageDetails] = useState<{
        currentPage: number;
        pageSize: number;
        total: number;
        totalPages: number;
    }>({currentPage: 1, pageSize: 60, total: 0, totalPages: 1});

    const fetchDecisions = async () => {
        getPopularDecisions(popularPageDetails)
            .then(decisionsData => {
                setPopularDecisions(decisionsData.decisions);
                setPopularPageDetails({
                    currentPage: decisionsData.currentPage,
                    pageSize: decisionsData.pageSize,
                    total: decisionsData.total,
                    totalPages: decisionsData.totalPages,
                })
            })
            .catch((error: AxiosError) => {
                // todo implement toast or something else
                console.error(error);
            })
            .finally(() => setPopularLoading(false));

        getTrendingDecisions(trendingPageDetails)
            .then(decisionsData => {
                setTrendingDecisions(decisionsData.decisions);
                setTrendingPageDetails({
                    currentPage: decisionsData.currentPage,
                    pageSize: decisionsData.pageSize,
                    total: decisionsData.total,
                    totalPages: decisionsData.totalPages,
                })
            })
            .catch((error: AxiosError) => {
                // todo implement toast or something else
                console.error(error);
            })
            .finally(() => setTrendingLoading(false));

        categories.forEach(category => {
            getDecisionsByCategory({category, pageSize: 50, currentPage: 1})
                .then(decisionsData => {
                    setCategoryDecisions(state => ({
                        ...state,
                        [category]: decisionsData
                    }));
                })
                .catch((error: AxiosError) => {
                    // todo implement toast or something else
                    console.error(error);
                })
                .finally(() => setCategoryLoading(false));
        });
    };

    useEffect(() => {
        fetchDecisions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearchTermChange = debounce(() => {
        setPopularLoading(true);
        setTrendingLoading(true);


        const term = (searchTerm.current?.value || '').toLowerCase();
        if (!term) {
            fetchDecisions()
            return;
        }

        setPopularDecisions(popularDecisions.filter(decision => {
            return decision.name?.toLowerCase().includes(term) || decision.author?.toLowerCase().includes(term);
        }));
        setTrendingDecisions(trendingDecisions.filter(decision => {
            return decision.name?.toLowerCase().includes(term) || decision.author?.toLowerCase().includes(term);
        }));
        setCategoryDecisions(categoryDecisions => {
            const result = {};
            Object.keys(categoryDecisions).forEach(key => {
                // @ts-ignore
                result[key] = {
                    ...categoryDecisions[key],
                    decisions: categoryDecisions[key].decisions.filter(decision => {
                        return decision.name?.toLowerCase().includes(term) || decision.author?.toLowerCase().includes(term);
                    })
                }
            });

            return result;
        });
        setPopularLoading(false);
        setTrendingLoading(false);

    }, 300);

    const onPopularNextPage = () => {
        if (popularPageDetails.currentPage === popularPageDetails.totalPages) {
            return;
        }

        getPopularDecisions({
            ...popularPageDetails,
            currentPage: popularPageDetails.currentPage + 1,
        })
            .then(decisionsData => {
                setPopularDecisions((oldDecisions) => [
                    ...oldDecisions,
                    ...decisionsData.decisions
                ]);
                setPopularPageDetails({
                    currentPage: decisionsData.currentPage,
                    pageSize: decisionsData.pageSize,
                    total: decisionsData.total,
                    totalPages: decisionsData.totalPages,
                })
            })
            .catch((error: AxiosError) => {
                // todo implement toast or something else
                console.error(error);
            })
            .finally(() => setPopularLoading(false));
    };

    const onTrendingNextPage = () => {
        if (trendingPageDetails.currentPage === trendingPageDetails.totalPages) {
            return;
        }

        getTrendingDecisions({
            ...trendingPageDetails,
            currentPage: trendingPageDetails.currentPage + 1,
        })
            .then(decisionsData => {
                setTrendingDecisions((oldDecisions) => [
                    ...oldDecisions,
                    ...decisionsData.decisions
                ]);
                setTrendingPageDetails({
                    currentPage: decisionsData.currentPage,
                    pageSize: decisionsData.pageSize,
                    total: decisionsData.total,
                    totalPages: decisionsData.totalPages,
                })
            })
            .catch((error: AxiosError) => {
                // todo implement toast or something else
                console.error(error);
            })
            .finally(() => setPopularLoading(false));
    };

    const onCategoriesDecisionsNextPage = (category: string) => {
        const categoryDecisionsData = categoryDecisions[category];
        if (!categoryDecisionsData) {
            return;
        }
        if (categoryDecisionsData.currentPage === categoryDecisionsData.totalPages) {
            return;
        }

        getDecisionsByCategory({
            category,
            pageSize: categoryDecisionsData.pageSize,
            currentPage: categoryDecisionsData.currentPage + 1,
            })
            .then(decisionsData => {
                setCategoryDecisions(state => ({
                    ...state,
                    [category]: {
                        ...decisionsData,
                        decisions: [...state[category].decisions, ...decisionsData.decisions],
                    }
                }));
            })
            .catch((error: AxiosError) => {
                // todo implement toast or something else
                console.error(error);
            })
            .finally(() => setCategoryLoading(false));
    };

    return (
        <div className='applications'>
            <div className='content-header'>
                <div className='decisions-types'>
                    <span key='popular' onClick={() => scrollToAnchor('popular')}>Popular</span>
                    <span key='trending' onClick={() => scrollToAnchor('trending')}>Trending</span>
                    {categories.map(category => {
                        const isCategoryDisabled = categoryDecisions?.[category]?.decisions.length === 0;
                        return (
                            <span
                                key={category}
                                onClick={() => scrollToAnchor(category)}
                                className={isCategoryDisabled ? 'disabled' : ''}
                                title={isCategoryDisabled ? 'No decisions were found that fall under this category' : undefined}
                            >
                            {category}
                        </span>
                        );
                    })}
                </div>
                <input
                    className='search'
                    type="search"
                    placeholder="Search..."
                    ref={searchTerm}
                    onChange={onSearchTermChange}
                />
            </div>

            <div className='decisions'>
                <DecisionSection
                    title='Popular'
                    decisions={popularDecisions}
                    isLoading={isPopularLoading}
                    id='popular'
                    total={popularPageDetails.total}
                    onReachEnd={onPopularNextPage}
                />
                <DecisionSection
                    title='Trending'
                    decisions={trendingDecisions}
                    isLoading={isTrendingLoading}
                    id='trending'
                    total={trendingPageDetails.total}
                    onReachEnd={onTrendingNextPage}
                />
                {categories.map(category => {
                    let decisions: Decision[] = [];
                    let total = 0;
                    if (categoryDecisions[category]) {
                        decisions = categoryDecisions[category].decisions;
                        total = categoryDecisions[category].total;
                    }
                    return (
                        <DecisionSection
                            key={category + 'apps'}
                            title={category}
                            decisions={decisions}
                            isLoading={isCategoryLoading}
                            id={category}
                            total={total}
                            onReachEnd={() => onCategoriesDecisionsNextPage(category)}
                        />
                    )
                })}
                {popularDecisions.length === 0
                    && trendingDecisions.length === 0
                    && !isPopularLoading
                    && !isTrendingLoading
                    && (
                        <div>No decisions found.</div>
                    )}
            </div>
        </div>
    );
};

export default Applications;